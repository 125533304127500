<script>
import { SuawListSection, SuawInputGroup, SuawTextInput, SuawSeriesMemberCard, SuawEmptyState } from "@/components";
import { GetCurrentEventRsvpedAndCheckedInList, GetPreviousEventAttendeeList } from "../../operations.gql";
import { GetEventSeriesWritersList } from "../../operations.gql";
import * as SeriesApi from "../../api";
import { getDisplayLocation } from "../../common";
export default {
  name: "WritersSearch",
  components: {
    SuawListSection,
    SuawInputGroup,
    SuawTextInput,
    SuawSeriesMemberCard,
    SuawEmptyState
  },
  props: {
    seriesId: {
      type: String,
      required: true
    },
    eventId: {
      type: String,
      required: true
    },
    isAttendanceTime: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      resultGetEventSeriesWritersList: [],
      resultGetCurrentEventRsvpedAndCheckedInList: {
        id: "",
        starts_at: "",
        rsvpedMembers: [],
        checkedInMembers: [],
        event_series: {
          id: "",
          isOrganizer: []
        }
      },
      resultGetPreviousEventAttendeeList: [],
      writersListSearchQuery: ""
    };
  },
  apollo: {
    resultGetEventSeriesWritersList: {
      query: GetEventSeriesWritersList,
      variables() {
        return {
          seriesId: this.seriesId
        };
      },
      skip() {
        return !this.seriesId;
      },
      loadingKey: "loadingQueriesCount"
    },
    resultGetCurrentEventRsvpedAndCheckedInList: {
      query: GetCurrentEventRsvpedAndCheckedInList,
      variables() {
        return {
          id: this.eventId
        };
      },
      skip() {
        return !this.eventId;
      },
      loadingKey: "loadingQueriesCount"
    },
    resultGetPreviousEventAttendeeList: {
      query: GetPreviousEventAttendeeList,
      variables() {
        return {
          starts_at: this.startsAt,
          event_series_id: this.seriesId
        };
      },
      skip() {
        return !this.startsAt || !this.seriesId;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasEvent() {
      return this.resultGetCurrentEventRsvpedAndCheckedInList.id !== "";
    },
    startsAt() {
      return this.hasEvent ? this.resultGetCurrentEventRsvpedAndCheckedInList?.starts_at : null;
    },
    isVirtual() {
      return this.hasEvent ? this.resultGetCurrentEventRsvpedAndCheckedInList?.is_virtual : null;
    },
    isAuthenticatedOrganizer() {
      const organizers = this.resultGetCurrentEventRsvpedAndCheckedInList.event_series.isOrganizer.map(org => org.user_id);
      return this.$auth.isAuthenticated && organizers.includes(this.$auth.user.id);
    },
    writersList() {
      // Retrieve all the RSVPed, Checked-In, and Previous Attendees' user IDs
      const rsvpedMemberIds = this.resultGetCurrentEventRsvpedAndCheckedInList.rsvpedMembers.map(member => member.user_view.id);
      const checkedInMemberIds = this.resultGetCurrentEventRsvpedAndCheckedInList.checkedInMembers.map(member => member.user_view.id);
      const previousAttendeeIds =
        this.resultGetPreviousEventAttendeeList.length > 0 ? this.resultGetPreviousEventAttendeeList[0].attendees.map(attendee => attendee.user_view.id) : [];
      // Map over all writers in the series
      return this.resultGetEventSeriesWritersList.map(writer => {
        const userId = writer.user_view.id;
        const isRsvped = rsvpedMemberIds.includes(userId);
        const isRecentAttendee = previousAttendeeIds.includes(userId);
        const isInAttendance = checkedInMemberIds.includes(userId);

        return {
          ...writer.user_view, // Spread the user properties
          is_organizer: this.isAuthenticatedOrganizer,
          is_rsvped: isRsvped, // True if the user has RSVPed
          is_recent_attendee: isRecentAttendee, // True if they attended the last event and didn't RSVP
          is_in_attendance: isInAttendance // True if they have checked in for the current event
        };
      });
    },
    filteredWritersList() {
      const lowerSearchQuery = this.writersListSearchQuery.toLowerCase();
      return this.writersList.filter(writer => writer.display_name.toLowerCase().includes(lowerSearchQuery));
    }
  },
  methods: {
    getDisplayLocation,
    onProfileClick(userId) {
      this.$router.push({
        name: "UserProfile",
        params: {
          userId: userId
        }
      });
    },
    async onAttendanceChange({ userId, attended }) {
      if (attended) {
        const result = await SeriesApi.checkIn(this.eventId, userId);
        if (result.success) {
          await this.$apollo.queries.resultGetCurrentEventRsvpedAndCheckedInList.refetch();
        } else {
          await this.$apollo.queries.resultGetCurrentEventRsvpedAndCheckedInList.refetch();
          this.$root.$emit("universal-error-message", result.error);
        }
      }
    }
  }
};
</script>

<template>
  <SuawInputGroup direction="column" group-gap="triple">
    <SuawTextInput v-model="writersListSearchQuery" type="search" size="large" placeholder="Search members..." />
    <SuawEmptyState v-if="isLoading" message="Loading writers..." size="medium" />
    <SuawEmptyState v-else-if="!isLoading && filteredWritersList.length === 0" message="No members found" />
    <SuawListSection v-else>
      <SuawSeriesMemberCard
        v-for="writer in filteredWritersList"
        :key="writer.id"
        :user-id="writer.id"
        :avatar-url="writer.avatar_url"
        :avatar-color="writer.avatar_color"
        :avatar-initials="writer.initials"
        :is-avatar-hidden="writer.is_avatar_hidden"
        :is-deactivated="writer.deleted_at !== null"
        :display-name="writer.display_name"
        :location="getDisplayLocation(writer.city, writer.region, writer.country)"
        :is-rsvped="writer.is_rsvped"
        :is-recent-attendee="writer.is_recent_attendee"
        :is-in-attendance="writer.is_in_attendance"
        :is-organizer="writer.is_organizer"
        :is-event-time="isVirtual ? false : isAttendanceTime"
        :event-count="writer.event_count.aggregate.count"
        :post-count="writer.post_count.aggregate.count"
        @profile-click="onProfileClick"
        @attendance-checkbox-click="onAttendanceChange"
      />
    </SuawListSection>
  </SuawInputGroup>
</template>
